import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Image } from 'src/components/image'
import shopify from 'api/shopify'
import { encode } from 'shopify-gid'
import BlockContent from "@sanity/block-content-to-react"

import { useStore, useAddItemToCart, setCustomerInState, useCartTickets } from 'src/context/siteContext'

import { Serializer } from "src/utils/serializer"
import { Newsletter } from 'src/components/newsletter'
import { trackPageView, trackAddToCart } from "src/components/analytics"

const ToyDescription = ({ desc }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <div className='w-full mono text-left'>
      <button className='text-18' onClick={() => setOpen(!isOpen)}>Description <span className={cx('relative', {
        'transform inline-block rotate-45': isOpen
      })}>+</span></button>
      {isOpen && (
        <div className='mt-4'>
          <BlockContent blocks={desc} serializers={Serializer} />
        </div>)}
    </div>
  )
}

export const ToyStore = () => {
  const addItemToCart = useAddItemToCart()
  const { customerName, customerTickets, checkout, toys } = useStore()
  const cartTickets = useCartTickets()
  const updateCustomerInState = setCustomerInState()
  useEffect(() => {
    updateCustomerInState()
    trackPageView('toy-store')
  }, [0])
  const checkInventory = (toy) => {
    if (window.PRODUCT_INVENTORY) {
      const currentItem = window.PRODUCT_INVENTORY.filter(value => {
        return value.id === toy.content.shopify.defaultVariant.variantId
      })

      if ( currentItem[0] && currentItem[0].available) {
        return toy.content.main.vendingValue
      } else {
        return  toy.content.main.toyOutOfStock || 'Coming Soon'
      }
    }
  }

  const handleAdd = (toy) => {
    const shopifyId = encode("Product", toy.content.shopify.productId, {
      accessToken: "c422a19a24242fb3a7aa6296dfab54da",
    })

    shopify.product.fetch(shopifyId).then((product: any) => {
      if (product) {
        const customAttributes = [
          {key: '_subTitle', value: toy.content.main.subTitle },
          {key: '_image', value: toy.content.main.image },
          {key: '_ticketAdd', value: `-${toy.content.main.ticketValue}` },
          {key: '_isToy', value: `true` },
        ]
        addItemToCart(product.variants[0].id, 1, customAttributes).then(() => {
        
          trackAddToCart(
            {
              name: toy.content.main.title,
              id: product.variants[0].id || "error",
              price: parseFloat(toy.content.shopify.defaultPrice),
              variant: toy.content.main.title || "error",
            },
            1,
            checkout
          )
          console.log('we did it after?')
        })
      }
    })
  }
  return (
    <div className='container--l mxa p1 outer ac'>
      <div className='x al mt1 ac'>
        <div>
          <h2 className='caps h3 mb0 pb0 offlimits'>Latest Toys {customerName}</h2>
          <span className='h5 caps mono ls1 mt1 block s14'>{toys.length} toys available</span>
        </div>
        <div className='toy__store-wrapper p-4 800:p-0 grid grid-cols-1 1000:gap-24 1000:grid-cols-2'>
          {toys.map(toy => {
            return (
            <div key={toy._id} className={cx('w-full', {
              '1000:col-span-2': toy.content.main.featuredToy
            })}>
              <div className='x fw text-left toy__store-row-f mt-8 800:mt-0 mb-2 f jcb aic mb1'>
                <div className={cx('x toy__store-row-name w-full 800:w-9/12 ', {
                  '800:w-10/12': toy.content.main.featuredToy
                })}>
                  <span className='text-24'>{toy.content.main.title}</span>
                </div>
                <div className=' f 800:justify-end w-5/12 800:w-5/12 mt1 aic'>
                  <span className='text-20 800:text-24 pb-1  border-solid border-2 pl-2 pr-2 rounded-md border-white jcc f mr05'>
                    ${toy.content.shopify.defaultPrice}
                  </span>
                  <div>
                    <button onClick={() => handleAdd(toy)} className='text-20 800:hidden 800:text-24 pb-1 uppercase border-solid border-2 pl-2 pr-2 rounded-md bg-yellow border-yellow text-black jcc f'>ADD</button>
                    <span className='text-20 hidden 800:inline 800:text-24 pb-1  border-solid border-2 pl-2 pr-2 rounded-md bg-yellow border-yellow text-black jcc f'>{checkInventory(toy)}</span>
                  </div>
                </div>
              </div>
              {toy.content.main.productDescription && (<ToyDescription desc={toy.content.main.productDescription} />)}
              <div className='relative bg-white overflow-hidden mt-4 rounded-3xl p-2 '>
                {toy.content.main.videoUrl && (
                    <div className='absolute top left w-full h-full'>
                      <video src={toy.content.main.videoUrl} className='absolute w-full h-full top left z-10' autoPlay loop playsInline muted />
                    </div>
                  )}
                {toy.content.main.singleImage && (<Image className='x toy__store-image mxa' imageId={toy.content.main.singleImage.asset._ref} alt={toy.content.main.title} />)}
              </div>
            </div>
          )})}
        </div>
        <div className='max-w-600 mx-auto p-6 mt-10 mb-10 800:mt-20 800:mb-20 mxa'>
          <div>
            <h3 className='caps offlimits text-40 800:text-60 800:leading-60 800:mb-4'>Get Notified when we release new toys</h3>
            <p className='text-24 mxa 800:mb-6'>We'll be releasing Offlimits Originals and limited editions as we eat more cereal. Stay Tuned.</p>
            <Newsletter arrowClass='cw' />
          </div>
        </div>
      </div>
    </div>
  )
}
