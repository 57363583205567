import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import shopify from 'api/shopify'
import { encode } from 'shopify-gid'

import { useAddItemToCart, useStore, useCartTickets } from 'src/context/siteContext'
const defaultVending = '00000000'
import { trackPageView, trackAddToCart } from "src/components/analytics"


export const TicketController = ( { authed }: { authed: boolean }) => {
  const addItemToCart = useAddItemToCart()
  const cartTickets = useCartTickets()
  const { customerTickets, toys } = useStore()
  const [vendingValue, setVendingValue] = useState(authed ? 'Login' : customerTickets)
  const [valid, setInvalid] = useState(false)
  const [pressedValue, setPressedValue] = useState('')
  const [buttonValue, setButtonValue] = useState('Enter Code')
  const setPressed = (value: string) => {
    setPressedValue(state => state + value)
  }
  useEffect(() => {
    setVendingValue(customerTickets + cartTickets)
    setInvalid(false)
  }, [authed, customerTickets, cartTickets])
  useEffect(() => {
    if (pressedValue !== '') {
      setVendingValue(pressedValue)
    } else {
      // setVendingValue('2000')
    }
    if (pressedValue.length === 2) {
      setButtonValue('Get Toy')
    }
    // if (pressedValue.length > 7) {
    //   setInvalid(true)
    //   setPressedValue('invalid')
    //   setTimeout(() => {
    //     setPressedValue('')
    //     setVendingValue(defaultVending)
    //     setButtonValue('Enter Code')
    //     setInvalid(false)
    //   }, 1500)
    // }
  }, [pressedValue])
  const clearPressed = () => {
    setTimeout(() => {
      setPressedValue('')
      setInvalid(false)
      setButtonValue('Enter Code')
      setVendingValue(customerTickets + cartTickets)
    }, 1500)
  }
  const toySelect = () => {
    setInvalid(true)
    setVendingValue('pending')
    // Check the toy value against the store
    let invalid = true
    setTimeout(() => {
      toys.forEach(toy => {
        if (toy.content.main.vendingValue.toLowerCase() === pressedValue.toLowerCase()) {
          // if ((customerTickets + cartTickets) < toy.content.main.ticketValue) {
          //   setPressedValue('NO FUNDS')

          //   setTimeout(() => {
          //     clearPressed()
          //   }, 2000)
          // } else {
            invalid = false
            setPressedValue('VENDING')
            const shopifyId = encode("Product", toy.content.shopify.productId, {
              accessToken: "c422a19a24242fb3a7aa6296dfab54da",
            })

            shopify.product.fetch(shopifyId).then((product: any) => {
              if (!product.variants[0].available) {
                setPressedValue('ERROR')
                clearPressed()
              } else {
                const customAttributes = [
                  {key: '_subTitle', value: toy.content.main.subTitle },
                  {key: '_image', value: toy.content.main.image },
                  {key: '_ticketAdd', value: `-${toy.content.main.ticketValue}` },
                  {key: '_isToy', value: `true` },
                ]
                addItemToCart(product.variants[0].id, 1, customAttributes).then(() => {
                  console.log('we did it!')
                  clearPressed()
                  trackAddToCart(
                    {
                      name: toy.content.main.title,
                      id: product.variants[0].id || "error",
                      price: parseFloat(toy.content.shopify.defaultPrice),
                      variant: toy.content.main.title || "error",
                    },
                    1,
                    checkout
                  )
                })
              }
            })
            setPressedValue('')
          // } 
        }
      })
      if (invalid) {

        console.log('invalid toy')
        setTimeout(() => {
          setVendingValue('invalid')
        }, 1000)
        setTimeout(() => {
          clearPressed()
        }, 2000)
      }
    }, 1000)
  }
  return (
    <div className={cx('x ac')}>
      <h5 className='small mb05 mono caps ls1'>Tickets Available</h5>
      <div className='vending__numbers rel al bcb cw f jcc aic x'>
        <div className='rel'>
          <h4 className='digital abs left z2 h2 x'>{vendingValue}</h4>
          <h4 className='ac digital h2 vending__numbers-default x'>{defaultVending}</h4>
        </div>
      </div>
      <div className='vending__keypad'>
        <div className='row f jcb aic'>
          <button disabled={valid} className='s48 founders' onClick={() => setPressed('a')}>
            <span>A</span>
            </button>
          <button disabled={valid} className='s48 founders' onClick={() => setPressed('b')}>
            <span>B</span>
            </button>
          <button disabled={valid} className='s48 founders' onClick={() => setPressed('c')}>
            <span>C</span>
            </button>
        </div>
        <div className='row f jcb aic'>
          <button disabled={valid} className='s48 founders' onClick={() => setPressed('1')}>
            <span>1</span>
            </button>
          <button disabled={valid} className='s48 founders' onClick={() => setPressed('2')}>
            <span>2</span>
            </button>
          <button disabled={valid} className='s48 founders' onClick={() => setPressed('3')}>
            <span>3</span>
            </button>
        </div>
        <div className=''>
          <button disabled={valid} className='x s48 founders' onClick={() => toySelect()}>
            <span>{buttonValue}</span></button>
        </div>
      </div>
    </div>
  )
}