import React, { useEffect } from 'react'
import cx from 'classnames'

// @ts-ignore

import { TicketController } from 'src/components/auth/ticketController'
import { ToyStore } from '../components/auth/toyStore'

import { useSetPage } from 'src/context/siteContext'

const ToyStoreLander = ({
  pageContext
}: {
  pageContext: {}
}) => {
  const setPage = useSetPage()
  useEffect(() => {
    setPage('accounts')
  }, [0])
  return (
    <div id='account__container pb-20' className='accounts__route bcblue'>
        <React.Fragment>
          <div className='nav-spacer' />
        </React.Fragment>
      <div className='p1 pb-20'>
      <div className='container--xl account__auth-inner mxa relative z-10 outer py1'>
        <div className='account__vending jcb aist'>
          <div className={cx('account__screen x bcb  rel jcc aic cw')}>
            <div className='absolute pointer-events-none account__nested use-select-none w-full h-full top left border-2 border-solid border-white z-10' style={{ zIndex: 100000}} />
            <div className='account__tickets abs x y top left z1'>
              <img className='account__tickets-top abs ' src='/tickets.png' alt='ticket images' />
              <img className='account__tickets-bottom abs ' src='/tickets.png' alt='ticket images' />
            </div>
            <div className='account__screen-inner rel z2'>
              <img src='/stars_1.png' className='abs top left x y star__background' />
              <img src='/stars_2.png' className='abs top left x y star__background_2' />
              <div className='rel z1'>
                <ToyStore />
              </div>
            </div>
          </div>
          <div className='account__vending-side jcb aic p1'>
            <TicketController />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ToyStoreLander
